/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';

const oyenAPI = async (
  token?: string | null | Promise<string>
): Promise<AxiosInstance> => {
  const APIKit = axios.create({
    timeout: 10000,
  });
  const t = await Promise.resolve(token);
  if (t) {
    APIKit.interceptors.request.use((config) => {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${t}`;
      }
      return config;
    });
  }

  return APIKit;
};

export default oyenAPI;
